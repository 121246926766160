import React, { useEffect } from "react";
import { useValidation } from "../../components/common/useValidation";

const API_URL = "https://api.goyu-study.com/api"; // Define the base API URL

const UsernameField = ({ username, onChange, usernameAvailable, setUsernameAvailable }) => {
  const usernamePattern = /^(?=.*[a-z])(?=.*[0-9])[a-z0-9]{4,16}$/; // 영문 소문자 + 숫자 조합, 4~16자

  const isValid = useValidation(username, usernamePattern);

  useEffect(() => {
    if (isValid) {
      const checkUsername = async () => {
        try {
          const response = await fetch(
            `${API_URL}/auth/signup/check-username?username=${username}`
          );
          const isAvailable = await response.json();
          setUsernameAvailable(isAvailable);
        } catch (error) {
          setUsernameAvailable(false);
        }
      };
      checkUsername();
    } else {
      setUsernameAvailable(null); // Reset state if format is invalid
    }
  }, [username, isValid]);

  return (
    <div className={`form-group ${usernameAvailable === false ? "error" : usernameAvailable ? "success" : ""}`}>
      <label>아이디 *</label>
      <input
        type="text"
        name="username"
        value={username}
        onChange={onChange}
        required
        placeholder="영문 소문자/숫자 조합, 4~16자"
      />
      {!isValid && (
        <p className="error-text">아이디는 영문 소문자와 숫자를 포함하여 4~16자여야 합니다.</p>
      )}
      {isValid && usernameAvailable === false && (
        <p className="error-text">이미 사용 중인 아이디입니다.</p>
      )}
      {isValid && usernameAvailable && (
        <p className="success-text">사용 가능한 아이디입니다.</p>
      )}
    </div>
  );
};

export default UsernameField;
