import React, { useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../Sign/AuthContext"; // AuthContext에서 memberId 가져오기
import "./PassEdit.css";
import PassPredictionLayout from "./PassPredictionLayout"; // 레이아웃 임포트

const API_URL = "https://api.goyu-study.com/api"; // Define the base API URL

function PassEdit() {
  const location = useLocation();
  const navigate = useNavigate();
  const { memberId } = useAuth(); // 동적으로 memberId 가져오기
  const initialData = location.state?.result;

  const [year, setYear] = useState(initialData?.year || 2024); // 기본값 2024
  const [regionName, setRegionName] = useState(initialData?.regionName || "");
  const [score, setScore] = useState(initialData?.score || "");

  const handleUpdate = async () => {
    try {
      if (!memberId) {
        alert("로그인 정보가 없습니다. 다시 로그인해주세요.");
        navigate("/signin");
        return;
      }

      console.log("Request Data:", { year, regionName, score: parseFloat(score) });

      await axios.put(
        `${API_URL}/prediction/update`, // Use the production API URL
        { year, regionName, score: parseFloat(score) },
        { params: { memberId } } // 동적으로 memberId 사용
      );

      alert("수정이 완료되었습니다.");
      navigate("/passprediction/status", {
        state: {
          year, // 수정된 응시년도
          regionName, // 수정된 지역명
          score: parseFloat(score), // 수정된 점수
          memberId, // 현재 로그인된 사용자 ID
        },
      });

    } catch (error) {
      console.error("Error during update:", error);
      alert("수정 중 문제가 발생했습니다.");
    }
  };

  return (
    <PassPredictionLayout>
      <div className="passedit-container">
        <h2>합격예측 수정</h2>
        <div className="form-container">
          {/* Year */}
          <div className="input-group">
            <label htmlFor="year">응시년도</label>
            <input
              type="number"
              id="year"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            />
          </div>

          {/* Region */}
          <div className="input-group">
            <label htmlFor="region">응시지역</label>
            <select
              id="region"
              value={regionName}
              onChange={(e) => setRegionName(e.target.value)}
            >
              <option value="">선택하세요</option>
              <option value="SEOUL">서울</option>
              <option value="GYEONGGI">경기</option>
              <option value="INCHEON">인천</option>
              <option value="SEJONG">세종</option>
              <option value="DAEJEON">대전</option>
              <option value="DAEGU">대구</option>
              <option value="GWANGJU">광주</option>
              <option value="ULSAN">울산</option>
              <option value="BUSAN">부산</option>
              <option value="GANGWON">강원</option>
              <option value="CHUNGBUK">충북</option>
              <option value="CHUNGNAM">충남</option>
              <option value="JEONBUK">전북</option>
              <option value="JEONNAM">전남</option>
              <option value="GYEONGBUK">경북</option>
              <option value="GYEONGNAM">경남</option>
              <option value="JEJU">제주</option>
            </select>
          </div>

          {/* Score */}
{/* Score */}
<div className="input-group">
  <label htmlFor="score">1차 점수 (교직논술 + 교육과정)</label>
  <input
    type="number"
    id="score"
    value={score}
    onChange={(e) => {
      const value = parseFloat(e.target.value);
      if (value <= 100) {
        setScore(e.target.value);
      } else {
        alert("점수는 100.00 이하로 입력해주세요.");
      }
    }}
    max="100" // 점수 제한
    step="0.01" // 소수점 두 자리까지 입력 가능
    placeholder="점수를 입력하세요"
  />
</div>


          <button className="submit-button" onClick={handleUpdate}>
            수정 완료
          </button>
        </div>
      </div>
    </PassPredictionLayout>
  );
}

export default PassEdit;
