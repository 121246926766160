import React from "react";

function AgreementCheckbox({ checked, onChange }) {
  const openTerms = () => {
    // terms.html 파일을 새 탭에서 엽니다.
    window.open("/terms.html", "_blank");
  };

  return (
    <div className="agreement-section">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        required
      />
      <span> [필수] 개인정보 수집 및 이용 동의</span>
      <button type="button" onClick={openTerms} style={{ marginLeft: "10px" }}>
        [보기]
      </button>
    </div>
  );
}

export default AgreementCheckbox;
