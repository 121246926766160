import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // useNavigate 임포트
import UsernameField from "./UsernameField";
import PasswordField from "./PasswordField";
import EmailField from "./EmailField";
import VerificationField from "./VerificationField";
import AgreementCheckbox from "./AgreementCheckbox";
import "./styles.css";

const API_URL = "https://api.goyu-study.com/api";

function SignupForm() {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    name: "",
    email: "",
    verificationCode: "",
  });

  const [usernameAvailable, setUsernameAvailable] = useState(null);
  const [emailAvailable, setEmailAvailable] = useState(null);
  const [verificationSent, setVerificationSent] = useState(false);
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const navigate = useNavigate(); // navigate 초기화

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    console.log("회원가입 버튼 클릭됨, 데이터:", formData);
    try {
      const response = await fetch(`${API_URL}/auth/signup`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("회원가입 성공 응답:", data);
        setResponseMessage(data.message);
        window.alert("회원가입 완료 되었습니다."); // 성공 팝업 표시
        navigate("/"); // 메인 페이지로 리다이렉트
      } else {
        const errorData = await response.json();
        console.error("회원가입 실패 응답:", errorData);
        setResponseMessage(errorData.result || "회원가입에 실패했습니다.");
      }
    } catch (error) {
      console.error("회원가입 요청 중 오류 발생:", error);
      setResponseMessage("회원가입 중 오류가 발생했습니다.");
    }
  };

  useEffect(() => {
    console.log("usernameAvailable 상태:", usernameAvailable);
    console.log("emailAvailable 상태:", emailAvailable);
    console.log("agreementChecked 상태:", agreementChecked);
  }, [usernameAvailable, emailAvailable, agreementChecked]);

  return (
    <div className="signup-container">
      <h2 className="signup-title">회원 가입</h2>
      <form onSubmit={handleSignup} className="signup-form">
        <UsernameField
          username={formData.username}
          onChange={handleChange}
          usernameAvailable={usernameAvailable}
          setUsernameAvailable={setUsernameAvailable}
        />
        <PasswordField
          password={formData.password}
          confirmPassword={formData.confirmPassword}
          onChange={handleChange}
        />
        <div className="form-group">
          <label>이름 *</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            placeholder="이름을 입력하세요"
          />
        </div>
        <EmailField
          email={formData.email}
          onChange={handleChange}
          emailAvailable={emailAvailable}
          setEmailAvailable={setEmailAvailable}
          setVerificationSent={setVerificationSent}
        />
        {verificationSent && (
          <VerificationField
            verificationCode={formData.verificationCode}
            onChange={handleChange}
            email={formData.email}
          />
        )}
        <AgreementCheckbox
          checked={agreementChecked}
          onChange={(e) => setAgreementChecked(e.target.checked)}
        />
        <button
          type="submit"
          className="signup-button"
          disabled={!usernameAvailable || !emailAvailable || !agreementChecked}
        >
          회원가입
        </button>
      </form>
      {responseMessage && <p className="response-message">{responseMessage}</p>}
    </div>
  );
}

export default SignupForm;
