import React, { useState } from "react";
import axios from "axios";

const API_URL = "https://api.goyu-study.com/api"; // Base API URL

const FindAccount = () => {
  const [activeTab, setActiveTab] = useState("id"); // "id" or "password"
  const [formData, setFormData] = useState({ email: "", name: "" });
  const [message, setMessage] = useState("");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setFormData({ email: "", name: "" }); // Reset form
    setMessage("");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let url = "";
      let payload = {};

      if (activeTab === "id") {
        url = `${API_URL}/auth/find-id`;
        payload = { email: formData.email, name: formData.name };
      } else if (activeTab === "password") {
        url = `${API_URL}/auth/find-password`;
        payload = { email: formData.email };
      }

      const response = await axios.post(url, payload);
      setMessage(
        activeTab === "id"
          ? `아이디: ${response.data.username}`
          : "임시 비밀번호가 이메일로 전송되었습니다."
      );
    } catch (error) {
      setMessage(error.response?.data?.message || "요청에 실패했습니다.");
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.tabs}>
        <button
          style={activeTab === "id" ? styles.activeTab : styles.tab}
          onClick={() => handleTabChange("id")}
        >
          아이디 찾기
        </button>
        <button
          style={activeTab === "password" ? styles.activeTab : styles.tab}
          onClick={() => handleTabChange("password")}
        >
          비밀번호 찾기
        </button>
      </div>

      <form style={styles.form} onSubmit={handleSubmit}>
        {activeTab === "id" && (
          <>
            <input
              style={styles.input}
              type="text"
              name="name"
              placeholder="이름"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
            <input
              style={styles.input}
              type="email"
              name="email"
              placeholder="이메일"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </>
        )}
        {activeTab === "password" && (
          <input
            style={styles.input}
            type="email"
            name="email"
            placeholder="이메일"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        )}
        <button style={styles.button} type="submit">
          {activeTab === "id" ? "아이디 찾기" : "비밀번호 찾기"}
        </button>
      </form>

      {message && <p style={styles.message}>{message}</p>}
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "400px",
    margin: "0 auto",
    textAlign: "center",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "10px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
  tabs: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  tab: {
    flex: 1,
    padding: "10px 20px",
    cursor: "pointer",
    border: "none",
    background: "#f9f9f9",
    fontWeight: "bold",
    color: "#555",
  },
  activeTab: {
    flex: 1,
    padding: "10px 20px",
    cursor: "pointer",
    border: "none",
    background: "#007BFF",
    color: "#fff",
    fontWeight: "bold",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    marginBottom: "15px",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  message: {
    marginTop: "20px",
    color: "green",
    fontWeight: "bold",
  },
};

export default FindAccount;
