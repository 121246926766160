import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

const ProtectedRoute = ({ children }) => {
    const { isLoggedIn } = useAuth();

    // 인증되지 않은 경우 로그인 페이지로 리디렉션
    if (!isLoggedIn) {
        return <Navigate to="/signin" replace />;
    }

    // 인증된 경우 자식 컴포넌트 렌더링
    return children;
};

export default ProtectedRoute;
