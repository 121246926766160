import { GiSandsOfTime } from "react-icons/gi";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    height: 100vh;
    background-color: #f9f9f9;
    color: #333;
    text-align: center;
`;

const Clock = styled.div`
    font-size: 100px;
    color: #79e5cb;
    margin-bottom: 20px;
`;

const Message = styled.p`
    font-size: 24px;
    margin: 0;
    
    span {
        color: #79e5cb;
        font-weight: bold;
    }
`;

const NotFound = () => {
    return (
        <Container>
            <Clock><GiSandsOfTime /></Clock>
            <Message>페이지 <span>준비중</span> 입니다.</Message>
        </Container>
    )
}

export default NotFound;