import React, { useEffect } from "react";
import { useValidation } from "../../components/common/useValidation";

const API_URL = "https://api.goyu-study.com/api"; // Define the base API URL

const EmailField = ({ email, onChange, emailAvailable, setEmailAvailable, setVerificationSent }) => {
  const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/; // 이메일 형식

  const isValid = useValidation(email, emailPattern);

  useEffect(() => {
    if (isValid) {
      const checkEmail = async () => {
        try {
          const response = await fetch(
            `${API_URL}/auth/signup/check-email?email=${email}`
          );
          const isAvailable = await response.json();
          setEmailAvailable(isAvailable);
        } catch (error) {
          setEmailAvailable(false);
        }
      };
      checkEmail();
    } else {
      setEmailAvailable(null); // 잘못된 형식일 경우 초기화
    }
  }, [email, isValid]);

  const sendVerificationCode = async () => {
    try {
      const response = await fetch(`${API_URL}/auth/signup/mail/send?email=${email}`, {
        method: "POST",
      });
      const data = await response.json();
      if (response.ok) {
        setVerificationSent(true);
      } else {
        alert(data.message || "인증번호 전송에 실패했습니다.");
      }
    } catch (error) {
      alert("인증번호 전송 중 오류가 발생했습니다.");
    }
  };

  return (
    <div
      className={`form-group email-group ${
        emailAvailable === false ? "error" : emailAvailable ? "success" : ""
      }`}
    >
      <label>이메일 *</label>
      <input
        type="email"
        name="email"
        value={email}
        onChange={onChange}
        required
        placeholder="이메일 주소 입력"
      />
      <button type="button" onClick={sendVerificationCode} className="verify-button">
        인증번호받기
      </button>
      {!isValid && <p className="error-text">올바른 이메일 형식을 입력하세요.</p>}
      {isValid && emailAvailable === false && (
        <p className="error-text">이미 사용 중인 이메일입니다.</p>
      )}
      {isValid && emailAvailable && <p className="success-text">사용 가능한 이메일입니다.</p>}
    </div>
  );
};

export default EmailField;
