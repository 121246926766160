import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Sign/AuthContext"; // AuthContext에서 memberId 가져오기
import "./PassPrediction.css";
import PassPredictionLayout from "./PassPredictionLayout"; // 레이아웃 임포트

const API_URL = "https://api.goyu-study.com/api"; // Define the base API URL

function PassPrediction() {
  const [year, setYear] = useState("2024");
  const [region, setRegion] = useState("");
  const [score, setScore] = useState("");
  const navigate = useNavigate();
  const { memberId, regionName } = useAuth(); // AuthContext에서 memberId와 regionName 가져오기
  const [loading, setLoading] = useState(true);

  // 기존 데이터 확인 및 상태 페이지로 리다이렉션
  useEffect(() => {
    const checkExistingData = async () => {
      try {
        console.log("Checking existing data with memberId:", memberId);
        const savedRegion = localStorage.getItem("regionName");
        if (!savedRegion) {
          console.error("Region name is missing in localStorage!");
          setLoading(false);
          return;
        }

        const response = await axios.get(`${API_URL}/prediction/status`, {
          params: { memberId, regionName: savedRegion },
        });

        console.log("Response from /status:", response.data);

        // 응답 데이터가 없는 경우 (점수 입력 안된 경우)
        if (!response.data || response.data.totalParticipants === 0) {
          console.log("No existing data, staying in PassPrediction");
          setLoading(false); // 로딩 해제
          return; // PassPrediction 화면 유지
        }

        // 데이터가 존재하는 경우 (점수 입력된 경우)
        console.log("Navigating to status with data:", response.data);
        setLoading(false); // navigate 호출 전에 로딩 해제
        navigate("/passprediction/status", { state: response.data }); // 상태 전달
      } catch (error) {
        console.error("No existing data found:", error);
        setLoading(false); // 에러 발생 시 로딩 해제
      }
    };

    if (memberId) {
      checkExistingData();
    }
  }, [memberId, navigate]);

  // 추가된 useEffect: regionName이 있는 경우 바로 상태 확인
  useEffect(() => {
    const checkExistingData = async () => {
      if (!regionName) {
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${API_URL}/prediction/status`, {
          params: { memberId, regionName },
        });

        if (response.data) {
          navigate("/passprediction/status", { state: { ...response.data } });
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Failed to fetch status:", error);
        setLoading(false);
      }
    };

    if (memberId) {
      checkExistingData();
    }
  }, [memberId, regionName, navigate]);

  const handleSubmit = async () => {
    try {
      await axios.post(`${API_URL}/prediction/initial`, {
        memberId, // 동적으로 가져온 memberId
        year: parseInt(year), // 숫자로 변환
        regionName: region,
        score: parseFloat(score), // 숫자로 변환
      });

      // regionName과 memberId를 로컬스토리지에 저장
      localStorage.setItem("regionName", region);
      localStorage.setItem("memberId", memberId);

      navigate("/passprediction/status", { state: { regionName: region } });
    } catch (error) {
      console.error("Failed to save prediction:", error);
      alert("예측 데이터를 저장하는 데 실패했습니다.");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <PassPredictionLayout>
      <div className="passprediction-container">
        <div className="form-container">
          <h2>합격예측 - 처음 입력할 때</h2>
          <div className="input-group">
  <label htmlFor="year">응시년도</label>
  <select id="year" value={year} onChange={(e) => setYear(e.target.value)} disabled>
    <option value="2024">2024</option>
  </select>
</div>


          <div className="input-group">
            <label htmlFor="region">응시지역</label>
            <select id="region" value={region} onChange={(e) => setRegion(e.target.value)}>
              <option value="" disabled>
                선택하세요
              </option>
              <option value="SEOUL">서울</option>
              <option value="GYEONGGI">경기</option>
              <option value="INCHEON">인천</option>
              <option value="SEJONG">세종</option>
              <option value="DAEJEON">대전</option>
              <option value="DAEGU">대구</option>
              <option value="GWANGJU">광주</option>
              <option value="ULSAN">울산</option>
              <option value="BUSAN">부산</option>
              <option value="GANGWON">강원</option>
              <option value="CHUNGBUK">충북</option>
              <option value="CHUNGNAM">충남</option>
              <option value="JEONBUK">전북</option>
              <option value="JEONNAM">전남</option>
              <option value="GYEONGBUK">경북</option>
              <option value="GYEONGNAM">경남</option>
              <option value="JEJU">제주</option>
            </select>
          </div>

          <div className="input-group">
  <label htmlFor="score">1차 점수 (교직논술 + 교육과정)</label>
  <input
    type="number"
    id="score"
    value={score}
    onChange={(e) => {
      const value = parseFloat(e.target.value);
      if (value <= 100) {
        setScore(e.target.value);
      } else {
        alert("점수는 100.00 이하로 입력해주세요.");
      }
    }}
    max="100" // 점수 제한
    step="0.01" // 소수점 두 자리까지 입력 가능
    placeholder="점수를 입력하세요"
  />
</div>


          <button className="submit-button" onClick={handleSubmit}>
            완료
          </button>
        </div>
      </div>
    </PassPredictionLayout>
  );
}

export default PassPrediction;
