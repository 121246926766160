import { useState, useEffect } from "react";

export const useValidation = (value, pattern) => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (value) {
      setIsValid(pattern.test(value)); // 정규표현식 검증
    } else {
      setIsValid(false);
    }
  }, [value, pattern]);

  return isValid;
};
