import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from './AuthContext';
import styled from 'styled-components';
import mainImg from './img/mainImg.jpg';

const API_URL = "https://api.goyu-study.com/api"; // Define the base API URL

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 50px); /* Adjust height to account for footer space */
    font-family: Arial, sans-serif;
    background-color: #ffffff;
    padding-bottom: 100px; /* Space between content and footer */

    @media (max-width: 768px) {
        flex-direction: column;
        height: auto; /* Let height adjust naturally for mobile */
        padding-bottom: 40px;
    }
`;

const LeftImage = styled.div`
    flex: 3; /* Adjusted flex ratio to 3:1 */
    height: 80%; /* Set height to match SignInWrapper */
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 70%; /* Reduced image size */
        height: auto;
    }

    @media (max-width: 768px) {
        display: none; /* Hide on mobile screens */
    }
`;

const SignInWrapper = styled.div`
    flex: 1; /* Adjusted flex ratio to 3:1 */
    height: 80%; /* Match height with LeftImage */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Align content to the top */
    background-color: rgba(121, 229, 203, 0.2); /* 20% 연한 79E5CB */
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    max-width: 350px; /* Box width */
    text-align: center;
    margin-bottom: 20px; /* Add space below SignInWrapper */
    margin-right: 50px; /* Add space to the right of SignInWrapper */

    @media (max-width: 768px) {
        width: 90%; /* Adjust width for mobile */
        max-width: none;
        height: auto; /* Allow height to adjust naturally on mobile */
    }
`;

const Title = styled.h2`
    font-size: 22px; /* Adjusted font size */
    font-weight: bold;
    color: #333; /* 검정색 */
    margin-bottom: 10px;
`;

const Line = styled.div`
    width: 80%; /* Line width */
    height: 1px;
    background-color: #ccc; /* Line color */
    margin: 10px 0;
`;

const Description = styled.div`
    font-size: 16px;
    line-height: 1.6; /* Adjusted line height */
    margin-top: 10px;

    p:first-child {
        font-weight: bold;
        color: #0FB18A; /* Primary color */
    }

    p:last-child {
        color: #333; /* 검정색 */
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
`;

const Input = styled.input`
    width: 100%;
    padding: 12px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 14px;
`;

const SubmitButton = styled.button`
    background-color: #0FB18A;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    padding: 12px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0d8f73;
    }

    &:disabled {
        background-color: #e0e0e0;
        color: #a0a0a0;
        cursor: not-allowed;
    }
`;

const SignUpLink = styled.div`
    margin-top: 16px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    gap: 10px;

    a {
        color: #0FB18A;
        font-weight: bold;
        text-decoration: none;
        transition: color 0.3s;

        &:hover {
            color: #0d8f73;
        }
    }
`;

const SignIn = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const { login } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isProcessing) return;

        setIsProcessing(true);
        try {
            const response = await fetch(`${API_URL}/auth/login`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ username, password }),
            });

            if (response.ok) {
                const data = await response.json();
                if (data.token) {
                    const userInfoResponse = await fetch(`${API_URL}/auth/user-info`, {
                        method: "GET",
                        headers: { Authorization: `Bearer ${data.token}` },
                    });

                    if (userInfoResponse.ok) {
                        const userInfo = await userInfoResponse.json();
                        await login(data.token, userInfo.memberId); // 로그인과 함께 지역 정보 가져오기

                        navigate("/"); // 메인 페이지로 이동
                    } else {
                        console.error("Failed to fetch user info");
                    }
                } else {
                    alert("로그인 정보가 올바르지 않습니다.");
                }
            } else {
                alert("로그인에 실패했습니다.");
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <Container>
            <LeftImage>
                <img src={mainImg} alt="Main Visual" />
            </LeftImage>
            <SignInWrapper>
                <Title>고유한 학습터</Title>
                <Line />
                <Description>
                    <p>나를 위한 스터디원</p>
                    <p>아이디로 빠르게 로그인해보세요!</p>
                </Description>
                <Form onSubmit={handleSubmit}>
                    <Input
                        type="text"
                        placeholder="아이디"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                    <Input
                        type="password"
                        placeholder="비밀번호"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <SubmitButton type="submit" disabled={isProcessing}>
                        {isProcessing ? '로그인 중...' : '로그인'}
                    </SubmitButton>
                </Form>
                <SignUpLink>
                    <Link to="/find-account">아이디/비밀번호 찾기</Link> | <Link to="/signup">회원가입</Link>
                </SignUpLink>
            </SignInWrapper>
        </Container>
    );
};

export default SignIn;
