import React from "react";

const PasswordField = ({ password, confirmPassword, onChange }) => {
  const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{4,16}$/;

  return (
    <>
      <div className="form-group">
        <label>비밀번호 *</label>
        <input
          type="password"
          name="password"
          value={password}
          onChange={onChange}
          required
          placeholder="영문 대소문자/숫자 조합, 4~16자"
        />
        {!passwordPattern.test(password) && (
          <p className="error-text">비밀번호는 영문 대소문자와 숫자를 포함하여 4~16자여야 합니다.</p>
        )}
      </div>
      <div className="form-group">
        <label>비밀번호 확인 *</label>
        <input
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={onChange}
          required
          placeholder="비밀번호 재입력"
        />
        {password !== confirmPassword && (
          <p className="error-text">비밀번호가 일치하지 않습니다.</p>
        )}
      </div>
    </>
  );
};

export default PasswordField;
