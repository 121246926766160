import React, { createContext, useContext, useState } from "react";
import axios from "axios";

const AuthContext = createContext();

const API_URL = "https://api.goyu-study.com/api"; // Define the base API URL

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem("accessToken") || null);
    const [memberId, setMemberId] = useState(localStorage.getItem("memberId") || null);
    const [regionName, setRegionName] = useState(localStorage.getItem("regionName") || null); // 추가

    // 로그인 함수
    const login = async (jwtToken, userId) => {
        localStorage.setItem("accessToken", jwtToken);
        localStorage.setItem("memberId", userId);
        setToken(jwtToken);
        setMemberId(userId);

        // 멤버 ID로 지역 이름 가져오기
        try {
            const response = await axios.get(`${API_URL}/prediction/region`, {
                params: { memberId: userId },
            });

            const region = response.data.regionName;
            if (region) {
                localStorage.setItem("regionName", region);
                setRegionName(region);
            } else {
                localStorage.removeItem("regionName");
                setRegionName(null);
            }
        } catch (error) {
            console.error("Failed to fetch region name:", error);
        }
    };

    // 로그아웃 함수
    const logout = () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("memberId");
        localStorage.removeItem("regionName");
        setToken(null);
        setMemberId(null);
        setRegionName(null);
    };

    const isLoggedIn = !!token;

    return (
        <AuthContext.Provider value={{ token, memberId, regionName, login, logout, isLoggedIn }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
