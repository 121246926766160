import React from 'react';
import styled from 'styled-components';
import leftVerticalImage from '../../assets/images/세로배너1.jpg'; // 세로 배너 1
import rightVerticalImage from '../../assets/images/세로배너2.jpg'; // 세로 배너 2
import horizontalBanner1 from '../../assets/images/가로배너1.png'; // 가로 배너 1
import horizontalBanner2 from '../../assets/images/가로배너2.png'; // 가로 배너 2

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column; /* 세로 배치 */
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 100vh; /* 화면 전체 높이 */
  background-color: #ffffff; /* 배경색 */
`;

const HorizontalBanner = styled.div`
  width: 100%;
  margin: 10px 0; /* 위아래 여백 */
  display: none; /* 기본적으로 숨김 */
  justify-content: center;
  background-color: #f9f9f9;

  a img {
    max-width: 90%; /* 배너 크기 조정 */
    height: auto;
  }

  @media (max-width: 768px) {
    display: flex; /* 모바일에서만 표시 */
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  flex: 1;
`;

const SideImage = styled.a`
  width: 150px; /* 고정 너비 */
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%; /* 배너가 컨테이너 너비에 맞게 */
    height: auto; /* 비율 유지 */
  }

  @media (max-width: 768px) {
    display: none; /* 모바일에서는 숨김 */
  }
`;

const CentralContent = styled.div`
  flex: 1;
  max-width: 700px; /* 중앙 콘텐츠 너비 */
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
`;

const PassPredictionLayout = ({ children }) => {
  return (
    <LayoutContainer>
      {/* 상단 가로 배너 */}
      <HorizontalBanner>
        <a
          href="https://www.pmg.co.kr/user/pta/prof/prof_lecture.asp?pf=withus03"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={horizontalBanner1} alt="가로 배너 1" />
        </a>
      </HorizontalBanner>

      {/* 중앙 콘텐츠 */}
      <ContentContainer>
        <SideImage
          href="https://www.pmg.co.kr/user/pto/prof/prof_lecture.asp?pf=withus03"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={leftVerticalImage} alt="세로 배너 1" />
        </SideImage>

        <CentralContent>{children}</CentralContent>

        <SideImage
          href="https://www.instagram.com/withus_imgo/?igsh=cDZlaGV5eTZjOTlz"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={rightVerticalImage} alt="세로 배너 2" />
        </SideImage>
      </ContentContainer>

      {/* 하단 가로 배너 */}
      <HorizontalBanner>
        <a
          href="https://www.instagram.com/withus_imgo/?igsh=cDZlaGV5eTZjOTlz"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={horizontalBanner2} alt="가로 배너 2" />
        </a>
      </HorizontalBanner>
    </LayoutContainer>
  );
};

export default PassPredictionLayout;
