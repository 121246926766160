import styled from "styled-components";

const Section = styled.div`
  width: 100vw; /* 화면 가로 전체를 차지 */
  background-color: rgba(121, 229, 203, 0.1); /* 10% 투명도 */
  padding: 40px 20px; /* 섹션 내부 여백 */
  text-align: center; /* 텍스트 중앙 정렬 */
  margin: 0; /* 섹션 외부 여백 제거 */
  box-sizing: border-box; /* 패딩 포함 크기 계산 */
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #333; /* 텍스트 색상 */
`;

const SectionDescription = styled.p`
  font-size: 16px;
  color: #555; /* 설명 텍스트 색상 */
  line-height: 1.6; /* 줄 간격 조정 */
`;

const InfoSection = () => {
  return (
    <Section>
      <SectionTitle>고유한 학습터는</SectionTitle>
      <SectionDescription>
        초등임용생들을 위한
        <br />
        자기주도 학습 지향 사용자 맞춤형 UX의
        <br />
        모바일 학습플랫폼입니다.
      </SectionDescription>
    </Section>
  );
};

export default InfoSection;
