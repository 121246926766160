import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../Sign/AuthContext"; // AuthContext에서 memberId 가져오기
import "./PassStatus.css";
import PassPredictionLayout from "./PassPredictionLayout"; // 레이아웃 임포트

const API_URL = "https://api.goyu-study.com/api"; // Define the base API URL

function PassStatus() {
  const [status, setStatus] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { memberId } = useAuth(); // AuthContext에서 memberId 가져오기
  const regionName = location.state?.regionName || localStorage.getItem("regionName") || "SEOUL";

  // 추가: location.state가 없을 경우 리다이렉트
  useEffect(() => {
    if (!location.state) {
      console.error("No state passed to PassStatus, using localStorage instead");
      const savedRegionName = localStorage.getItem("regionName");
      if (!savedRegionName) {
        console.error("Region name missing from both state and localStorage");
        navigate("/passprediction");
        return;
      }
    }
    console.log("PassStatus received state:", location.state);
  }, [location.state, navigate]);

  const fetchStatus = async () => {
    if (!regionName || !memberId) {
      alert("필요한 정보가 없습니다. 다시 시도해주세요.");
      navigate("/passprediction");
      return;
    }

    try {
      const response = await axios.get(`${API_URL}/prediction/status`, {
        params: { regionName, memberId },
      });
      console.log("Response from /status:", response.data);
      setStatus(response.data);
    } catch (error) {
      console.error("Failed to fetch status:", error);
      alert("상태를 가져오는 데 실패했습니다.");
    }
  };

  // 상태 데이터 가져오기
  useEffect(() => {
    fetchStatus();
  }, [regionName, memberId]);

  // 상태 업데이트 디버깅
  useEffect(() => {
    console.log("Updated status:", status);
  }, [status]);

  if (!status || Object.keys(status).length === 0) {
    return <div className="loading">로딩 중...</div>;
  }

  return (
    <PassPredictionLayout>
      <div className="passstatus-container">
        <h2>합격예측 상태</h2>
        <p>전체 참여자 수: {status.totalParticipants} (최소 필요 인원: {status.minRequiredParticipants})</p>
        <p>지역 참여자 수: {status.regionParticipants} (최소 필요 인원: {status.regionMinParticipants})</p>
        <p>전제 충족 여부: {status.globalSatisfied ? "충족" : "불충족"}</p>
        <p>지역 충족 여부: {status.regionSatisfied ? "충족" : "불충족"}</p>
        <button
          className="result-button"
          disabled={!status.globalSatisfied}
          onClick={() =>
            navigate("/passprediction/result", {
              state: {
                regionName,
                globalSatisfied: status.globalSatisfied,
                regionSatisfied: status.regionSatisfied,
              },
            })
          }
        >
          결과보기
        </button>
        <button className="refresh-button" onClick={fetchStatus}>
          새로고침
        </button>
      </div>
    </PassPredictionLayout>
  );
}

export default PassStatus;
