import { useState, useEffect } from "react";

function useDaysLeft(targetDate) {
  const [daysLeft, setDaysLeft] = useState(0);

  useEffect(() => {
    const calculateDaysLeft = () => {
      const today = new Date();
      const target = new Date(targetDate);
      const diff = Math.ceil((target - today) / (1000 * 60 * 60 * 24));
      setDaysLeft(diff);
    };

    calculateDaysLeft();
    const timer = setInterval(calculateDaysLeft, 86400000); // 매일 업데이트
    return () => clearInterval(timer);
  }, [targetDate]);

  return daysLeft;
}

export default useDaysLeft;
