import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { useAuth } from "../Sign/AuthContext"; // AuthContext에서 memberId 가져오기
import "./PassResult.css";
import PassPredictionLayout from "./PassPredictionLayout"; // 레이아웃 임포트

Chart.register(CategoryScale);

const API_URL = "https://api.goyu-study.com/api"; // Define the base API URL

function PassResult() {
  const navigate = useNavigate();
  const [result, setResult] = useState(null);
  const location = useLocation();
  const { memberId } = useAuth(); // AuthContext에서 memberId 가져오기
  const regionName = location.state?.regionName || "지역 정보 없음";
  const globalSatisfied = location.state?.globalSatisfied || false;
  const regionSatisfied = location.state?.regionSatisfied || false;

  useEffect(() => {
    const fetchResult = async () => {
      try {
        if (!memberId) {
          alert("로그인 정보가 없습니다. 다시 로그인해주세요.");
          navigate("/signin");
          return;
        }

        const response = await axios.get(
          `${API_URL}/prediction/result/${memberId}`
        );
        setResult(response.data);
      } catch (error) {
        console.error("Failed to fetch result:", error);
        alert("결과를 가져오는 데 실패했습니다.");
      }
    };
    fetchResult();
  }, [memberId, navigate]);

  if (!result) return <div className="loading">로딩 중...</div>;

  const generateNormalDistribution = (mean, stdDev, totalPoints = 101) => {
    const data = [];
    const labels = [];
    const step = (6 * stdDev) / (totalPoints - 1); // X축 간격 (±3σ를 totalPoints로 나눔)
    let xStart = mean - 3 * stdDev; // X축 시작 (mean - 3σ)

    for (let i = 0; i < totalPoints; i++) {
      const x = xStart + i * step; // X축 값
      const y =
        Math.exp(-0.5 * Math.pow((x - mean) / stdDev, 2)) /
        (stdDev * Math.sqrt(2 * Math.PI)); // 정규분포 공식
      data.push(y); // Y축 값 추가
      labels.push(Math.round(x)); // X축 값 추가 (소수점 반올림)
    }

    return { data, labels };
  };

  const globalDistribution = generateNormalDistribution(
    result.globalDistribution.mean,
    result.globalDistribution.standardDeviation
  );
  const globalDistributionData = {
    labels: globalDistribution.labels, // X축 값
    datasets: [
      {
        label: "전체 정규분포",
        data: globalDistribution.data, // Y축 값
        borderColor: "blue",
        borderWidth: 2,
        fill: false,
      },
    ],
  };

  const regionDistribution = generateNormalDistribution(
    result.regionDistribution.mean,
    result.regionDistribution.standardDeviation
  );
  const regionDistributionData = {
    labels: regionDistribution.labels, // X축 값
    datasets: [
      {
        label: `${regionName} 정규분포`,
        data: regionDistribution.data, // Y축 값
        borderColor: "green",
        borderWidth: 2,
        fill: false,
      },
    ],
  };

  return (
    <PassPredictionLayout>
      <div className="passresult-container">
        <h2 className="title">[ 나의 위치는 ? ]</h2>
        <div className="chart-container">
          {globalSatisfied && (
            <div className="chart-wrapper">
              <Line data={globalDistributionData} />
              <div className="info-box">
                전체 평균<br />
                <span>{result.globalDistribution.mean}</span>
              </div>
            </div>
          )}
          {globalSatisfied && regionSatisfied && (
            <div className="chart-wrapper">
              <Line data={regionDistributionData} />
              <div className="info-box">
                {regionName} 평균<br />
                <span>{result.regionDistribution.mean}</span>
              </div>
            </div>
          )}
        </div>
        {globalSatisfied && regionSatisfied && (
          <div className="result-details">
            {[
              { label: "정확도", value: `${result.result.accuracy}%` },
              { label: "최소필요 2차 점수", value: `${result.result.differenceFromAverage} 이상 필요` },
              { label: "상위 퍼센트", value: `${result.result.percentile}%` },
              { label: "참여자 중", value: `${result.result.rankWithTies}등` },
              { label: "예측 등수", value: `${result.result.predictedRank}등` },
              { label: "예측 배수 범위", value: `${result.result.multiplier} 이내` },
            ].map((stat, index) => (
              <div key={index} className="stat-box">
                {stat.label}
                <br />
                <span>{stat.value}</span>
              </div>
            ))}
          </div>
        )}

        <button
          className="modify-button"
          onClick={() => navigate("/passprediction/edit", { state: { result } })}
          disabled={!globalSatisfied} // 전체 충족 여부만 검사
        >
          수정
        </button>
      </div>
    </PassPredictionLayout>
  );
}

export default PassResult;
