import { Outlet } from "react-router-dom";
import styled from "styled-components";

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* 화면 전체 높이 */
  background-color: #ffffff; /* 배경색 */
`;

const Content = styled.div`
  flex: 1; /* 남은 공간을 채움 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  width: 100%;
`;

const FooterWrapper = styled.footer`
  background-color: #f9f9f9; /* 푸터 배경색 */
  color: #000;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  border-top: 1px solid #ccc; /* 상단 경계선 */
`;

const RootLayout = () => {
  return (
    <Container>
      <Header />
      <Content>
        <Outlet />
      </Content>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Container>
  );
};

export default RootLayout;
