import React from "react";
import { RouterProvider, Navigate } from "react-router-dom";
import RootLayout from "./layout/root-layout";
import MainPage from "./pages/MainPage/MainPage";
import NotFound from "./pages/NotFound/NotFound";
import PassPrediction from "./pages/PassPrediction/PassPrediction";
import PassStatus from "./pages/PassPrediction/PassStatus";
import PassResult from "./pages/PassPrediction/PassResult";
import PassEdit from "./pages/PassPrediction/PassEdit";
import { AuthProvider } from "./pages/Sign/AuthContext"; // AuthContext 가져오기
import SignIn from "./pages/Sign/SignIn";
import SignupForm from "./components/SignupForm/SignupForm";
import { createBrowserRouter } from "react-router-dom";
import ProtectedRoute from "./pages/Sign/ProtectedRoute"; // ProtectedRoute 가져오기
import FindAccount from "./components/find/FindAccount";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />, // RootLayout을 상위에만 두기
    children: [
      {
        path: "/",
        element: <MainPage />,
        errorElement: <NotFound />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
      {
        path: "/passprediction",
        element: (
          <ProtectedRoute>
            <PassPrediction /> {/* PassPrediction만 렌더링 */}
          </ProtectedRoute>
        ),
      },
      {
        path: "/passprediction/status",
        element: (
          <ProtectedRoute>
            <PassStatus /> {/* PassStatus 페이지 */}
          </ProtectedRoute>
        ),
      },
      {
        path: "/passprediction/result",
        element: (
          <ProtectedRoute>
            <PassResult /> {/* PassResult 페이지 */}
          </ProtectedRoute>
        ),
      },
      {
        path: "/passprediction/edit",
        element: (
          <ProtectedRoute>
            <PassEdit /> {/* PassEdit 페이지 */}
          </ProtectedRoute>
        ),
      },
      {
        path: "/signin",
        element: <SignIn />,
      },
      {
        path: "/signup",
        element: <SignupForm />,
      },
      {
        path: "/find-account", // 통합된 경로
        element: <FindAccount />, // FindAccount 컴포넌트를 표시
      },
    ],
  },
]);

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
