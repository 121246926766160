import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import useDaysLeft from "../../hooks/useDaysLeft";
import "./Header.css";
import { useAuth } from "../../pages/Sign/AuthContext";

import instagramIcon from "../../assets/icons/위더스 인스타.png";
import chaeryeongBlogIcon from "../../assets/icons/채령블로그.png";
import wangkyungBlogIcon from "../../assets/icons/왕경블로그.png";
import chaeryeongYoutubeIcon from "../../assets/icons/채령 유튜브.png";
import wangkyungYoutubeIcon from "../../assets/icons/왕경 유튜브.png";
import webLogo from "../../assets/images/mainlogoweb.png";
import mobileLogo from "../../assets/images/mainlogomobile.png";

function Header() {
  const daysLeft = useDaysLeft("2025-11-08");
  const [menuOpen, setMenuOpen] = useState(false);
  const { isLoggedIn, logout } = useAuth();
  const navigate = useNavigate();

  const API_URL = "https://api.goyu-study.com/api";

  const handleLogout = async () => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      try {
        await fetch(`${API_URL}/auth/logout`, {
          method: "POST",
          headers: { Authorization: `Bearer ${token}` },
        });
      } catch (error) {
        console.error("Logout failed:", error);
      }
    }
    localStorage.removeItem("accessToken");
    logout();
    navigate("/");
  };
  


  return (
      <header className="header">
        <div className="top-bar">
          <div className="top-left icons">
            <a href="https://www.instagram.com/withus_imgo?igsh=cDZlaGV5eTZjOTlz" target="_blank" rel="noopener noreferrer">
              <img src={instagramIcon} alt="Instagram" title="위더스 인스타" />
            </a>
            <a href="https://blog.naver.com/goyoussam" target="_blank" rel="noopener noreferrer">
              <img src={chaeryeongBlogIcon} alt="Chaeryeong Blog" title="채령 블로그" />
            </a>
            <a href="https://blog.naver.com/k1n9999" target="_blank" rel="noopener noreferrer">
              <img src={wangkyungBlogIcon} alt="Wangkyung Blog" title="왕경 블로그" />
            </a>
            <a href="https://www.youtube.com/channel/UCcTMxmc54eARIeodg0JhGaQ" target="_blank" rel="noopener noreferrer">
              <img src={chaeryeongYoutubeIcon} alt="Chaeryeong YouTube" title="채령 유튜브" />
            </a>
            <a href="https://www.youtube.com/@king_yang" target="_blank" rel="noopener noreferrer">
              <img src={wangkyungYoutubeIcon} alt="Wangkyung YouTube" title="왕경 유튜브" />
            </a>
          </div>
          <div className="top-right">
            <span className="d-day">2026 임용 D-{daysLeft}</span>
            {isLoggedIn ? (
                <Link to="#" onClick={handleLogout}>로그아웃</Link>
            ) : (
                <>
                  <Link to="/signin">로그인</Link> | <Link to="/signup">회원가입</Link>
                </>
            )}
          </div>
        </div>

        <div className="nav-bar">
          <div className="nav-left">
            <picture>
              <source media="(max-width: 768px)" srcSet={mobileLogo} />
              <Link to="/">
                <img src={webLogo} alt="고유한 학습터 로고" className="logo" />
              </Link>
            </picture>
            <nav className="nav-links">
              <Link to="/study" className="nav-item">
                공부하기
              </Link>
              <Link to="/community" className="nav-item">
                커뮤니티
              </Link>
            </nav>
          </div>
          <div className="nav-actions">
  <a
    href="https://www.pmg.co.kr/user/pto/prof/prof_lecture.asp?pf=withus03"
    target="_blank"
    rel="noopener noreferrer"
    className="blink-pink"
  >
    2차 준비
  </a>
  <Link to="/passprediction" className="blink-green">
    합격예측
  </Link>
  <button className="menu-toggle" onClick={() => setMenuOpen(!menuOpen)}>
    ☰
  </button>
</div>

        </div>

        {menuOpen && (
            <div className="mobile-menu">
              <Link to="/study" onClick={() => setMenuOpen(false)}>
                공부하기
              </Link>
              <Link to="/community" onClick={() => setMenuOpen(false)}>
                커뮤니티
              </Link>
            </div>
        )}
      </header>
  );
}

export default Header;
