import React, { useState } from "react";

const API_URL = "https://api.goyu-study.com/api"; // Define the base API URL

function VerificationField({ verificationCode, onChange, email }) {
  const [verificationMessage, setVerificationMessage] = useState("");

  const verifyCode = async () => {
    try {
      const response = await fetch(`${API_URL}/auth/signup/verify-code`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, verificationCode }),
      });

      const data = await response.json();
      setVerificationMessage(data.message);
    } catch (error) {
      setVerificationMessage("인증번호 확인 중 오류가 발생했습니다.");
    }
  };

  return (
    <div className="form-group">
      <label>인증번호 *</label>
      <input
        type="text"
        name="verificationCode"
        value={verificationCode}
        onChange={onChange}
        required
        placeholder="이메일로 전송된 인증번호 입력"
      />
      <button type="button" onClick={verifyCode} className="verify-button">
        인증번호 확인
      </button>
      {verificationMessage && <p className="response-message">{verificationMessage}</p>}
    </div>
  );
}

export default VerificationField;
