import styled from 'styled-components';
import MainImg from "../../assets/images/MainImg.png";
import { Link } from 'react-router-dom';
import InfoSection from '../../components/MainContent/InfoSection';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: 80vh; /* 전체 화면 높이를 채움 */
    padding-bottom: 150px; /* 푸터 영역 확보 */
    box-sizing: border-box;
    overflow-x: hidden; /* 가로 스크롤 방지 */

`;

const MainWrapper = styled.div`
    position: relative;
    margin-top: 30px;

    img {
        width: 100%;
        max-width: 1000px;

        @media (max-width: 768px) {
            width: 800px;
            padding: 10px;
        }
    }
`;

const LearnButton = styled(Link)`
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 15px 30px;
    background-color: #0FB18A;
    color: white;
    font-weight: bold;
    font-size: 18px;
    border: none;
    border-radius: 10px;
    text-decoration: none;
    text-align: center;
`;

const MainPage = () => {
    return (
        <Container>
            <MainWrapper>
                <img src={MainImg} alt="Main" />
                <LearnButton to="/passprediction">합격예측</LearnButton>
            </MainWrapper>
            <InfoSection /> {/* InfoSection 컴포넌트를 추가 */}
        </Container>
    );
};

export default MainPage;
